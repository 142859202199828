'use client'
import NavigateLeftIcon from '@/app/icons/navigate-left'
import NavigateRightIcon from '@/app/icons/navigate-right'
import { cn } from '@/util/cn'
import { useEffect, useRef, useState } from 'react'
import { desktopWidth } from 'ui/src/constants'

type Props = { elementId: string; iconClassName?: string }
const containerCss = `absolute bottom-0 top-0 flex w-14 items-center px-2`
const backgroundCss = 'from-giphyBlack/100 to-giphyBlack/0 pointer-events-none absolute bottom-0 top-0 w-1/5'
const scrollAmount = 500

export const NavButtons = ({ elementId, iconClassName }: Props) => {
    const [left, setLeft] = useState(0)
    const [width, setWidth] = useState(0)
    const el = useRef<HTMLElement | null>()
    useEffect(() => {
        const ref = document.getElementById(elementId)
        el.current = ref
        setWidth(el.current?.scrollWidth || 0)
    }, [elementId, left])
    return (
        <>
            <div className={cn('transition-opacity', left === 0 && 'pointer-events-none opacity-0')}>
                <div className={cn(backgroundCss, 'left-0 bg-gradient-to-r')} />
                <div
                    className={cn(containerCss, 'left-0 justify-start')}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                >
                    <NavigateLeftIcon
                        className={cn('size-5 cursor-pointer', iconClassName)}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            if (el.current) {
                                setLeft(Math.max(0, el.current.scrollLeft - scrollAmount))
                                el.current.scrollLeft -= scrollAmount
                            }
                        }}
                    />
                </div>
            </div>
            <div className={cn('transition-opacity', left + desktopWidth > width && 'pointer-events-none opacity-0')}>
                <div className={cn(backgroundCss, 'right-0 bg-gradient-to-l')} />
                <div
                    className={cn(containerCss, 'right-0 justify-end')}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                >
                    <NavigateRightIcon
                        className={cn('size-5 cursor-pointer', iconClassName)}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            if (el.current) {
                                setLeft(el.current.scrollLeft + scrollAmount)
                                el.current.scrollLeft += scrollAmount
                            }
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default NavButtons
