'use client'
import { NexaBlack } from '@/styles/fonts'
import { giphyDarkestGrey, giphyIndigo, giphyPink, giphyPurple, giphyRed, giphyWhite } from '@giphy/colors'
import { setGADataLayer } from 'analytics'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import { KokoResponse } from './api'
import { KokoGuy, KokoLogo } from './icons'

const Container = styled.div<{ isAlarming: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 120px;
    margin-bottom: 50px;
    border-radius: 8px;
    ${({ isAlarming }) =>
        isAlarming
            ? css`
                  background: linear-gradient(-135deg, ${giphyRed} 0%, ${giphyPink} 100%);
              `
            : css`
                  background: linear-gradient(-135deg, ${giphyPurple} 0%, ${giphyIndigo} 100%);
              `}
`

const Title = styled.h3`
    font-family: ${NexaBlack.style.fontFamily};
    font-size: 26px;
    color: ${giphyWhite};
    margin-bottom: 0;
    margin-top: 0;
`

const Description = styled.p`
    margin-top: 3px;
    margin-bottom: 0;
    font-size: 16px;
    color: ${giphyWhite};
`

const LeftImage = styled.div`
    height: 120px;
    width: 133px;
    position: relative;

    svg {
        position: absolute;
        bottom: 0;
        transform: scale(-1, 1);
        height: 95px;
        fill: white;
    }
`

const LeftTexts = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-left: 18px;
`

const Right = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 50px;
`

const PartnershipText = styled.div`
    margin-bottom: 7px;
    font-size: 15px;
    font-weight: bold;
    opacity: 0.5;
`

const GetHelpButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 251px;
    font-size: 17px;
    font-weight: bold;
    color: ${giphyWhite};
    background: ${giphyDarkestGrey};
    border-radius: 4px;
    cursor: pointer;
`

const PartnershipContainer = styled.div`
    display: flex;
    svg {
        height: 20px;
        fill: white;
        margin-left: 8px;
    }
`

const CloseMessage = styled.i`
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    color: ${giphyWhite};
`

type KokoProps = {
    term: string
    preFetchedResponse?: KokoResponse
}

const Koko = ({ term, preFetchedResponse: data }: KokoProps) => {
    const [isClosed, setIsClosed] = useState<boolean>(false)

    const handleGetHelp = () => {
        setGADataLayer({
            event: 'banner_click',
            options: { banner: { bannerName: 'koko banner' }, search: { searchTerm: term } },
        })
        window.open(`https://r.kokocares.org/giphy?q=${term}`, '_blank')
    }

    if (!data || isClosed) {
        return null
    }

    return (
        data?.match_koko_keywords_list && (
            <Container isAlarming={!!data.is_alarming}>
                <div className="flex flex-1">
                    <LeftImage>
                        <KokoGuy />
                    </LeftImage>
                    <LeftTexts>
                        <Title>Is everything ok? {!data.is_alarming && '❤️'}</Title>
                        <Description>
                            If you or someone you know is struggling, you are not alone. Help is available.
                        </Description>
                    </LeftTexts>
                </div>
                <Right>
                    <PartnershipContainer>
                        <PartnershipText>Partnership with</PartnershipText>
                        <KokoLogo />
                    </PartnershipContainer>
                    <GetHelpButton onClick={handleGetHelp}>Get Help</GetHelpButton>
                </Right>
                <CloseMessage className="ss ss-icon ss-delete" onClick={() => setIsClosed(true)} />
            </Container>
        )
    )
}

export default Koko
