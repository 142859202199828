export const SearchTypeCopy = {
    gifs: 'GIFs',
    stickers: 'Stickers',
    clips: 'Clips',
} as const

export const SearchTypeToFetchType = {
    gifs: 'gifs',
    stickers: 'stickers',
    clips: 'videos',
} as const
